import { Button } from 'baseui/button';
import {Box, IconButton, InputAdornment, Typography} from '@mui/material';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Body,
  ButtonIcon,
  Container,
  ErrorText,
  InputWrapper,
  LoginField,
  LoginForgetPassword,
  LoginForm,
  LoginIcon,
  LoginInput,
  LoginSubmitButton,
  MainLogo,
  Screen,
  ScreenBackground,
  ScreenBackgroundShape1,
  ScreenBackgroundShape2,
  ScreenBackgroundShape3,
  ScreenBackgroundShape4,
  ScreenContent,
  SocialIcon,
  SocialIconsDiv,
} from './LoginStyles';
import i18n from '../../i18n';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { useFormik } from 'formik';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import config from '../../config';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import ClipLoader from 'react-spinners/ClipLoader';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from 'react-i18next';
import UKFlag from '../assets/UKFlag.svg';
import SpainFlag from '../assets/SpainFlag.svg';
import USFlag from '../assets/USFlag.svg';
import bcrypt from "bcryptjs";

function Login(props) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const signIn = useSignIn();
  const isAuthenticated = useIsAuthenticated();
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {t, i18n} = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const bcrypt = require('bcryptjs');

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (values) => {
    setError('');
    setIsLoading(true);

    const hashedPassword = bcrypt.hashSync(values.password, config.salt);

    await axios.post(
        `${config.baseURL}/api/v1/login`,
        {...values,
          password: hashedPassword},
      ).then(response => {
          signIn({
            token: props.setToken(response.data.access_token),
            expiresIn: 60,
            tokenType: 'Bearer',
            authState: { email: values.email },
          });
          navigate('/home');
          setIsLoading(false);
      }).catch(error => {
        setError(error.response.data.message);
        setIsLoading(false);
      });
  };

  const handleFormSubmit = async (values) => {
    await axios.post(
      `${config.baseURL}/api/v1/form/support/unauthorized`,
      values
    ).then(response => {
      setSubmitted(true);

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleIconClick = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit,
  });

  const supportForm = useFormik({
    initialValues: {
      email: '',
      message: '',
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <Body>
      <Container>
        <Screen>
          <ScreenContent>
            <MainLogo src="./FullLogo.png" />
            <LoginForm onSubmit={formik.handleSubmit}>
              {/*<HeadingXXLarge>Welcome Back!</HeadingXXLarge>*/}
              <Box minHeight={'30px'}>
                <ErrorText>{t(error)}</ErrorText>
              </Box>
              <LoginField>
                <LoginInput
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  placeholder={t("username")}
                  // clearOnEscape
                  // size="large"
                  type="text"
                  required={true}
                />
                <LoginIcon>
                  <PersonOutlineOutlinedIcon />
                </LoginIcon>
              </LoginField>
              <LoginField>
                <LoginInput
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  placeholder={t("password")}
                  // clearOnEscape
                  // size="large"
                  type={showPassword ? 'text' : 'password'}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}

                />
                <LoginIcon>
                  <LockOutlinedIcon />
                </LoginIcon>

              </LoginField>

              <LoginForgetPassword onClick={() => navigate('/forgot')}> {t("forgot_password")} </LoginForgetPassword>

              <InputWrapper>
                <LoginSubmitButton type="submit">
                  {/*size="large" kind="primary" isLoading={formik.isSubmitting}*/}
                  {isLoading ? <ClipLoader color="#262626" size={15} /> : t("login")}
                  <ButtonIcon>
                    <ArrowForwardIosOutlinedIcon />
                  </ButtonIcon>
                </LoginSubmitButton>
              </InputWrapper>
              <InputWrapper>
                <LoginSubmitButton onClick={() => navigate('/register')}>
                  {/*size="large" kind="secondary"*/}
                  {t("register")}
                  <ButtonIcon>
                    <ArrowForwardIosOutlinedIcon />
                  </ButtonIcon>
                </LoginSubmitButton>
              </InputWrapper>

              {/*<SocialIconsDiv>*/}
              {/*  /!*<SocialLogin  onClick={handleIconClick}>*!/*/}
              {/*  <SocialIcon onClick={handleIconClick}> <HeadsetMicOutlinedIcon /> </SocialIcon>*/}
              {/*  /!*</SocialLogin>*!/*/}
              {/*</SocialIconsDiv>*/}

              {/*<SocialIconsDiv>*/}
              {/*  <Box justifyContent={'center'} justifyItems={'center'} p={'5px'}>*/}
              {/*    <Button type="button" onClick={toggleLanguage} bgcolor={"#285c9a"}>*/}
              {/*      {currentLanguage === 'en' ? 'Español' : 'English'}*/}
              {/*    </Button>*/}
              {/*  </Box>*/}
              {/*</SocialIconsDiv>*/}

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box justifyContent={'center'} justifyItems={'center'}>
                  <Box onClick={toggleLanguage} style={{ cursor: 'pointer' }}>
                    {currentLanguage === 'en' ? <img src={SpainFlag} alt="Spain Flag" width="30px" height="30px"/> : <img src={USFlag} alt="US Flag" width="30px" height="30px"/>}
                  </Box>
                </Box>
                <SocialIcon onClick={handleIconClick}> <HeadsetMicOutlinedIcon /> </SocialIcon>
              </div>


              <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <Typography variant="h4" color="textPrimary" sx={{ m: 2 }}> {t("support_form_label4")} </Typography>
                <form onSubmit={supportForm.handleSubmit}>
                  <DialogContent>
                    <Box>
                      {/*<TextField name="email" label="Contact email" fullWidth required m={'10px'} />*/}
                      <TextField
                        name={'email'}
                        label={t("contact_email")}
                        value={supportForm.values.email}
                        onChange={supportForm.handleChange}
                        variant="outlined"
                        required={true}
                        style={{ marginBottom: '20px', width: '100%', color: '#262626' }}
                      />
                    </Box>
                    <Box m={'20px 0 0 0'}>
                      {/*<TextField name="message" label="Problem or Question" fullWidth required />*/}
                      <TextField
                        name={'message'}
                        label={t("problem_or_question")}
                        value={supportForm.values.message}
                        onChange={supportForm.handleChange}
                        variant="outlined"
                        required={true}
                        style={{ marginBottom: '20px', width: '100%', color: '#262626' }}
                      />
                    </Box>
                    {submitted &&
                      <Typography variant="body1" color="success"> {t("support_form_label1")} </Typography>}
                    <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
                      {t("support_form_label2")}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
                      {t("support_form_label3")}
                    </Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpen(false)}>{t("close_button")}</Button>
                    <Button type="submit">{t("submit_button")}</Button>
                  </DialogActions>
                </form>
              </Dialog>

            </LoginForm>


          </ScreenContent>
          <ScreenBackground>
            <ScreenBackgroundShape4 />
            <ScreenBackgroundShape3 />
            <ScreenBackgroundShape2 />
            <ScreenBackgroundShape1 />
          </ScreenBackground>
        </Screen>
      </Container>
    </Body>
  );
}

export { Login };
