import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { HeadingSmall } from 'baseui/typography';
import { Formik, useFormik } from 'formik';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import * as yup from 'yup';
import {
  Body,
  ButtonIcon,
  Container,
  ErrorText,
  InputWrapper,
  LoginForm,
  LoginSubmitButton,
  MainLogo,
  Screen,
  ScreenBackground,
  ScreenBackgroundShape1,
  ScreenBackgroundShape2,
  ScreenBackgroundShape3,
  ScreenBackgroundShape4,
  ScreenContent,
} from '../LoginStyles';
import { Box, TextField, InputAdornment, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import config from '../../../config';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import SpainFlag from '../../assets/SpainFlag.svg';
import UKFlag from '../../assets/UKFlag.svg';
// import bcrypt from "bcryptjs";


function Register(props: any) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isReferrerCodeValid, setIsReferrerCodeValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNameFilled, setIsNameFilled] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsBoxAccepted, setTermsBoxAccepted] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const referral = urlParams.get('referral');
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [open, setOpen] = useState(false);
  const bcrypt = require('bcryptjs');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    phone: yup.string()
      .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, t('Phone number is not valid!'))
      .required(t('Phone number is required')),
    password: yup.string()
      .min(8, t('Password must be at least 8 characters long'))
      .matches(/[^A-Za-z0-9]/, t('Password must contain at least one special character'))
      .required(t('Password is required')),
  });

  const validateReferrerCode = async (code: any) => {
    setError('');
    // Replace this with actual API call to validate the referrer code
    await axios.post(`${config.baseURL}/api/v1/referrer`, code).then(response => {
      setIsReferrerCodeValid(true);
      setIsLoading(false);
    }).catch(error => {
      setError(error.response.data.message ? error.response.data.message : 'Unknown error');
      setIsReferrerCodeValid(false);
      setIsLoading(false);
    });
  };

  const onNameSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (formik.values.firstName && formik.values.lastName) {
      setIsNameFilled(true);
    }
  };

  const handleIconClick = () => {
    setOpen(true);
  };

  const onReferrerCodeSubmit = async (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
    await validateReferrerCode(formik.values);
    setIsLoading(false);
  };

  const navigateToLogin = () => {
    navigate('/');
  };

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    setError('');

    const hashedPassword = bcrypt.hashSync(values.password, config.salt);

    if (isReferrerCodeValid) {
      await axios.post(
        `${config.baseURL}/api/v1/register`,
          {...values,
            password: hashedPassword,
          language: currentLanguage},
      ).then(response => {
        setIsSubmitted(true);
      }).catch(error => {
        setError(error.response.data.message ? error.response.data.message : 'Unknown error');
      });
    }
    setIsLoading(false);
  };

  // Add a function to handle changes to the checkbox
  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsBoxAccepted(event.target.checked);
  };

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phone: '',
      code: '',
      username: '',
    },
    validationSchema,
    onSubmit,
  });

  // const hashedPassword = bcrypt.hashSync(formik.values.password, salt);


  if (referral) {
    formik.values.code = referral;
  }

  return (
    <Body>
      <Container>
        <Screen>
          <ScreenContent>
            <MainLogo src="./FullLogo.png" />
            {!isSubmitted ? (
              <><>
                {!isReferrerCodeValid && (
                  <LoginForm onSubmit={onReferrerCodeSubmit}>
                    {/*<HeadingXLarge color={"#292929"}>Enter your referrer code</HeadingXLarge>*/}
                    <Box minHeight={'80px'}>
                      <ErrorText>{error}</ErrorText>
                    </Box>
                    <InputWrapper>
                      <TextField
                        name={'code'}
                        label={t('Referrer Code')}
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        variant="outlined"
                        required={true}
                        style={{ marginBottom: '20px', width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }} />
                    </InputWrapper>
                    <InputWrapper>
                      <LoginSubmitButton type="submit">
                        {isLoading ? <ClipLoader color="#262626" size={15} /> : t('Validate Referrer Code')}
                        <ButtonIcon>
                          <ArrowForwardIosOutlinedIcon />
                        </ButtonIcon>
                      </LoginSubmitButton>
                    </InputWrapper>
                    <InputWrapper>
                      <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                        {t('Go back to Login')}
                        <ButtonIcon>
                          <ArrowForwardIosOutlinedIcon />
                        </ButtonIcon>
                      </LoginSubmitButton>
                    </InputWrapper>
                  </LoginForm>
                )}
                <>
                  {isReferrerCodeValid && (
                    <>
                    {!termsAccepted && (
                      <LoginForm>
                        <Box minHeight={'230px'} bgcolor={'white'} borderRadius={'20px'} m={'10px'}
                             sx={{ overflowY: 'auto', maxHeight: '230px' }} p={'8px'}>
                          <div dangerouslySetInnerHTML={{ __html: t('terms_and_conditions') }} />
                        </Box>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={termsBoxAccepted}
                              onChange={handleTermsChange}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label={t('terms_and_conditions_text')}
                          sx={{fontWeight: 'bold', fontSize: '10px' }}
                        />
                        <InputWrapper>
                          <LoginSubmitButton type="submit" onClick={() => setTermsAccepted(true)}
                                             disabled={!termsBoxAccepted}>
                            {t('Accept')}
                            <ButtonIcon>
                              <ArrowForwardIosOutlinedIcon />
                            </ButtonIcon>
                          </LoginSubmitButton>
                        </InputWrapper>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Box justifyContent={'center'} justifyItems={'center'}>
                            <Box onClick={toggleLanguage} style={{ cursor: 'pointer' }}>
                              {currentLanguage === 'en' ?
                                <img src={SpainFlag} alt="Spain Flag" width="30px" height="30px" /> :
                                <img src={UKFlag} alt="UK Flag" width="30px" height="30px" />}
                            </Box>
                          </Box>
                        </div>
                      </LoginForm>
                    )}
                      {termsAccepted && (
                        <>
                          {!isNameFilled && (
                            <LoginForm onSubmit={onNameSubmit}>
                              <Box minHeight={'40px'}>
                                <ErrorText>{error}</ErrorText>
                              </Box>
                              <InputWrapper>
                                <TextField
                                  name={'firstName'}
                                  label={t('firstname')}
                                  value={formik.values.firstName}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }} />
                              </InputWrapper>
                              <InputWrapper>
                                <TextField
                                  name={'lastName'}
                                  label={t('lastname')}
                                  value={formik.values.lastName}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }} />
                              </InputWrapper>
                              <InputWrapper>
                                <LoginSubmitButton type="submit">
                                  {isLoading ? <ClipLoader color="#262626" size={15} /> : t('submit_button')}
                                  <ButtonIcon>
                                    <ArrowForwardIosOutlinedIcon />
                                  </ButtonIcon>
                                </LoginSubmitButton>
                              </InputWrapper>
                            </LoginForm>
                          )}
                          {isNameFilled && (
                            <LoginForm onSubmit={formik.handleSubmit}>
                              <Box minHeight={'40px'}>
                                <ErrorText>{error}</ErrorText>
                              </Box>
                              {/*<InputWrapper>*/}
                              {/*  <TextField*/}
                              {/*    name={'firstName'}*/}
                              {/*    label="First Name"*/}
                              {/*    value={formik.values.firstName}*/}
                              {/*    onChange={formik.handleChange}*/}
                              {/*    variant="outlined"*/}
                              {/*    required={true}*/}
                              {/*    style={{ width: '100%', color: '#262626' }}*/}
                              {/*  />*/}
                              {/*</InputWrapper>*/}
                              {/*<InputWrapper>*/}
                              {/*  <TextField*/}
                              {/*    name={'lastName'}*/}
                              {/*    label="Last Name"*/}
                              {/*    value={formik.values.lastName}*/}
                              {/*    onChange={formik.handleChange}*/}
                              {/*    variant="outlined"*/}
                              {/*    required={true}*/}
                              {/*    style={{ width: '100%', color: '#262626' }}*/}
                              {/*  />*/}
                              {/*</InputWrapper>*/}
                              <InputWrapper>
                                <TextField
                                  name={'phone'}
                                  label={t('phone')}
                                  type={'number'}
                                  value={formik.values.phone}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }}
                                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                                  helperText={formik.touched.phone && formik.errors.phone} />
                              </InputWrapper>
                              <InputWrapper>
                                <TextField
                                  name={'email'}
                                  label={t('Email Address')}
                                  value={formik.values.email}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }} />
                              </InputWrapper>
                              <InputWrapper>
                                <TextField
                                  name={'username'}
                                  label={t('username')}
                                  value={formik.values.username}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }} />
                              </InputWrapper>
                              <InputWrapper>
                                <TextField
                                  name={'password'}
                                  label={t('password')}
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  variant="outlined"
                                  required={true}
                                  style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }}
                                  type={showPassword ? 'text' : 'password'}
                                  error={formik.touched.password && Boolean(formik.errors.password)}
                                  helperText={formik.touched.password && formik.errors.password}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={handleClickShowPassword}
                                        >
                                          {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }} />
                              </InputWrapper>
                              <InputWrapper>
                                {/*<Button type="submit" size="large" kind="primary" isLoading={formik.isSubmitting}>*/}
                                {/*    Submit*/}
                                {/*</Button>*/}
                                <LoginSubmitButton type="submit">
                                  {isLoading ? <ClipLoader color="#262626" size={15} /> : 'Submit'}
                                  <ButtonIcon>
                                    <ArrowForwardIosOutlinedIcon />
                                  </ButtonIcon>
                                </LoginSubmitButton>
                              </InputWrapper>
                            </LoginForm>
                          )}
                        </>
                      )}
                    </>
                  )}

                </>
              </>
              </>
            ) : (
              <>
                <LoginForm>
                  <HeadingSmall alignContent={'center'}
                                color={'#262626'}> {t('Verification email sent to') + formik.values.email}</HeadingSmall>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                      {t('Go back to Login')}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                </LoginForm>
              </>
            )}

              </ScreenContent>
          <ScreenBackground>
            <ScreenBackgroundShape1 />
            <ScreenBackgroundShape2 />
            <ScreenBackgroundShape3 />
            <ScreenBackgroundShape4 />
          </ScreenBackground>
              </Screen>
              </Container>
              </Body>
  )
            }

export { Register };
