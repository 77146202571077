import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ProgressCircle from '../../../ProgressCircle';
import { tokens } from '../../../theme';
import axios from 'axios';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';


const ActivePacks = (props) => {
  const theme = useTheme();
  const [activePacks, setActivePacks] = React.useState([]);
  const [activePromotionalPacks, setActivePromotionalPacks] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { t, i18n } = useTranslation();


  const getActivePacks = async () => {
    try {
      await axios.get(
        `${config.baseURL}/api/v1/info/user/active_packs`,
        {
          headers: {
            Authorization: 'Bearer ' + props.token,
          },
        },
      ).then(response => {
        setIsLoading(false);
        setActivePacks(response.data);
      });

    } catch (error) {
      console.error(error);
    }
  };


  const getActivePromotionalPacks = async () => {
    try {
      await axios.get(
          `${config.baseURL}/api/v1/info/user/promotional_active_packs`,
          {
            headers: {
              Authorization: 'Bearer ' + props.token,
            },
          },
      ).then(response => {
        setIsLoading(false);
        setActivePromotionalPacks(response.data);
      });

    } catch (error) {
      console.error(error);
    }
  };


  const getGradientColor = (productName) => {
    switch (productName) {
      case 'Enterprising':
        return 'linear-gradient(180deg, #f2f0f0 30%, #287233 90%)';
      case 'Executive':
        return 'linear-gradient(180deg, #f2f0f0 30%, #3E285C 90%)';
      case 'CEO':
        return 'linear-gradient(180deg, #f2f0f0 30%, #F6A700 90%)';

      case 'Promotional Enterprising':
        return 'linear-gradient(180deg, #f2f0f0 30%, #06FA4F 90%)';
      case 'Promotional Executive':
        return 'linear-gradient(180deg, #f2f0f0 30%, #DA06FA 90%)';
      case 'Promotional CEO':
        return 'linear-gradient(180deg, #f2f0f0 30%, #FAF206 90%)';
      default:
        return '#f2f0f0'; // default color if no match
    }
  };

  useEffect(() => {
    getActivePacks().then(r => console.log(r));
    getActivePromotionalPacks().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}

      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            {t('activePacks_title')}
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            {t('activePacks_subtitle')}
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {activePacks.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box sx={{background: getGradientColor(product.name)}} p="20px" borderRadius="8px" minHeight="300px" display="flex" flexDirection="column"
                   justifyContent="space-between">
                <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                  <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }} color={"#FFFFFF"}>
                    {t(product.name)}
                  </Typography>
                </Box>
                {product.description.map((desc, i) => (
                  <Typography key={i} variant="h4" color="text.secondary" textAlign={'center'}>
                    {t(desc)}
                  </Typography>
                ))}
                <Box bgcolor="#FFFFFF" p="10px" borderRadius="8px" m="10px 0 10px 0">
                  <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                    {t("dashboard_active_pack_investment")}
                  </Typography>
                  <Typography variant="h4" color="primary" sx={{ textAlign: 'center' }}>
                    {/*{product.price}*/}
                    ${parseFloat(product.price).toLocaleString()}
                  </Typography>
                  {/*<GreenLinearProgress variant="determinate" value={progress} valueBuffer={300}/>*/}
                  {/*<Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>*/}
                  {/*  Price Bonus: {progress}*/}
                  {/*</Typography>*/}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mt="25px"
                >
                  <>
                    <ProgressCircle size="125" progress={String(product.percentage * 100)} max="300" /><Typography
                    variant="h5" color="#000000" fontWeight="bold">
                    {parseFloat(product.percentage * 100).toLocaleString()}%
                  </Typography>
                  </>
                </Box>
              </Box>
            </Grid>
          ))}
          {activePromotionalPacks.map((product, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Box sx={{background: getGradientColor(product.name)}} p="20px" borderRadius="8px" minHeight="300px" display="flex" flexDirection="column"
                     justifyContent="space-between">
                  <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                    <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }} color={"#FFFFFF"}>
                      {t(product.name)}
                    </Typography>
                  </Box>
                  {product.description.map((desc, i) => (
                      <Typography key={i} variant="h4" color="text.secondary" textAlign={'center'}>
                        {t(desc)}
                      </Typography>
                  ))}
                  <Box bgcolor="#FFFFFF" p="10px" borderRadius="8px" m="10px 0 10px 0">
                    <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                      {t("dashboard_active_pack_investment")}
                    </Typography>
                    <Typography variant="h4" color="primary" sx={{ textAlign: 'center' }}>
                      {/*{product.price}*/}
                      ${parseFloat(product.price).toLocaleString()}
                    </Typography>
                    {/*<GreenLinearProgress variant="determinate" value={progress} valueBuffer={300}/>*/}
                    {/*<Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>*/}
                    {/*  Price Bonus: {progress}*/}
                    {/*</Typography>*/}
                  </Box>
                  <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      mt="25px"
                  >
                    <>
                      <ProgressCircle size="125" progress={String(product.percentage * 100)} max="300" /><Typography
                        variant="h5" color="#000000" fontWeight="bold">
                      {parseFloat(product.percentage * 100).toLocaleString()}%
                    </Typography>
                    </>
                  </Box>
                </Box>
              </Grid>
          ))}
        </Grid>
      )}
    </Box>

  );
};

export default ActivePacks;