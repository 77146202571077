import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { tokens } from '../../theme';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import LineChart from '../../LineChart';
import BarChart from '../../BarChart';
import StatBox from '../../StatBox';
import ProgressCircle from '../../ProgressCircle';
import React, { useEffect, useState } from 'react';
import CodeBox from '../../CodeBox';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import SavingsIcon from '@mui/icons-material/Savings';
import config from '../../../config';
import { Navigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {useTranslation} from "react-i18next";

function AdminDashboard(props, { isAdmin }) {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  // const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [balance, setBalance] = useState(0.00);
  const [activePacks, setActivePacks] = React.useState([]);
  const [userRefferals, setUserRefferals] = React.useState([]);
  const [userData, setUserData] = useState({
    deposit: 0.00,
    fee: 0.00,
    purchases: 0.00,
    withdraw: 0.00
  });
  const [token, setToken] = useState('');
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  const {t, i18n} = useTranslation();
  const columns = [
    { field: 'code', headerName: 'Referrer Code', width: 200 },
    {
      field: 'name',
      headerName: 'Name',
      cellClassName: 'name-column--cell',
      width: 250,
    },
    { field: 'username', headerName: 'Username', width: 200 },
    { field: 'phone', headerName: 'Phone Number', width: 200 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'join_date', headerName: 'Join date', width: 300 },
  ];

  const columnsTrans = [
    { field: 'user', headerName: t('history_table_user'), width: 200},
    { field: 'date', headerName: t('history_table_date'), width: 200 },
    { field: 'ref', headerName: t('history_table_reference'), width: 400 },
    {
      field: 'description',
      headerName: t('history_table_description'),
      width: 600,
      cellClassName: 'name-column--cell',
      renderCell: ({ row: { description } }) => {
        return <Typography>{t(description)}</Typography>;
      }
    },
    {
      field: 'amount',
      headerName: t('history_table_amount'),
      width: 100,
      renderCell: ({ row: { amount, operation } }) => {
        if (operation === 4) {
          return <Typography color={colors.redAccent[500]}>-${parseFloat(amount).toLocaleString()}</Typography>;
        }
        if (operation === 2) {
          return <Typography color={colors.redAccent[500]}>-${parseFloat(amount).toLocaleString()}</Typography>;
        } else {
          return <Typography color={colors.greenAccent[500]}>+${parseFloat(amount).toLocaleString()}</Typography>;
        }
      },
    },
  ];

  const getHistoryTransData = async () => {
    await axios.get(
        `${config.baseURL}/api/v1/info/user/admin/transactions`,
        {
          headers: {
            Authorization: 'Bearer ' + props.token,
          },
        },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setTransactionHistory(response.data);
        setIsLoading(false);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const getAllUsersData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/user/referrals`,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserRefferals(response.data);
        setIsLoading(false);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const getData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/user/admin`,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
    getAllUsersData().then(r => console.log(r));
    getHistoryTransData().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}

      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            Admin Panel
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            Welcome to your admin panel
          </Typography>
        </Box>
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <><Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#285c9a"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={parseFloat(userData.deposit).toLocaleString()}
                subtitle="Deposits"
                progress="0.75"
                icon={<MonetizationOnIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#285c9a"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={parseFloat(userData.fee).toLocaleString()}
                subtitle="Fees"
                progress="0.50"
                icon={<PersonAddIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#285c9a"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridColumn={{ xs: 1, sm: 2, md: 3 }}
            >
              <StatBox
                title={parseFloat(userData.purchases).toLocaleString()}
                subtitle="Purchases"
                progress="1"
                icon={<AccountBalanceWalletIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#285c9a"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridColumn={{ xs: 1, sm: 2, md: 3 }}
            >
              <StatBox
                title={parseFloat(userData.withdraw).toLocaleString()}
                subtitle="Withdraw"
                progress="1"
                icon={<AccountBalanceWalletIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
            </Box>
          </Grid>
          {/*<Grid xs={12} sm={12} md={6} lg={3} xl={3}>*/}
          {/*  <Box*/}
          {/*    width="100%"*/}
          {/*    backgroundColor="#285c9a"*/}
          {/*    display="flex"*/}
          {/*    alignItems="center"*/}
          {/*    justifyContent="center"*/}
          {/*  >*/}
          {/*    <StatBox*/}
          {/*      title={parseFloat(userData.deposit).toLocaleString()}*/}
          {/*      subtitle="Deposits"*/}
          {/*      progress="0.75"*/}
          {/*      icon={*/}
          {/*        <MonetizationOnIcon*/}
          {/*          sx={{ color: '#FFFFFF', fontSize: '26px' }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          {/*<Grid xs={12} sm={12} md={6} lg={3} xl={3}>*/}
          {/*  <Box*/}
          {/*    width="100%"*/}
          {/*    backgroundColor="#285c9a"*/}
          {/*    display="flex"*/}
          {/*    alignItems="center"*/}
          {/*    justifyContent="center"*/}
          {/*  >*/}
          {/*    <StatBox*/}
          {/*      title={parseFloat(userData.fee).toLocaleString()}*/}
          {/*      subtitle="Fees"*/}
          {/*      progress="0.50"*/}
          {/*      icon={*/}
          {/*        <PersonAddIcon*/}
          {/*          sx={{ color: '#FFFFFF', fontSize: '26px' }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          {/*<Grid xs={12} sm={12} md={6} lg={3} xl={3}>*/}
          {/*  <Box*/}
          {/*    width="100%"*/}
          {/*    backgroundColor="#285c9a"*/}
          {/*    display="flex"*/}
          {/*    alignItems="center"*/}
          {/*    justifyContent="center"*/}
          {/*    gridColumn={{ xs: 1, sm: 2, md: 3 }}*/}
          {/*  >*/}
          {/*    <StatBox*/}
          {/*      title={parseFloat(userData.purchases).toLocaleString()}*/}
          {/*      subtitle="Purchases"*/}
          {/*      progress="1"*/}
          {/*      icon={*/}
          {/*        <AccountBalanceWalletIcon*/}
          {/*          sx={{ color: '#FFFFFF', fontSize: '26px' }}*/}
          {/*        />*/}
          {/*      }*/}
          {/*    />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#285c9a"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gridColumn={{ xs: 1, sm: 2, md: 3 }}
            >
              <CodeBox
                title={parseInt(userData.total_users).toLocaleString()}
                subtitle="Total Users"
                progress="1"
                icon={<AccountBalanceWalletIcon
                  sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
            </Box>
          </Grid>

        </Grid>

        <Box
          m="8px 0 0 0"
          width="100%"
          height="70vh"
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#285c9a',
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: '#285c9a',
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={userRefferals}
            columns={columns}
            getRowId={(row) => row.code}
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
              LoadingOverlay: function CustomLoadingOverlay() {
                return <CircularProgress />;
              },
            }} />
        </Box>

        <Box
        m="8px 0 0 0"
        width="100%"
        height="70vh"
        sx={{
        '& .MuiDataGrid-root': {
        border: 'none',
      },
        '& .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
        '& .name-column--cell': {
        color: colors.greenAccent[300],
      },
        '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#285c9a',
        borderBottom: 'none',
      },
        '& .MuiDataGrid-virtualScroller': {
        backgroundColor: colors.primary[400],
      },
        '& .MuiDataGrid-footerContainer': {
        borderTop: 'none',
        backgroundColor: '#285c9a',
      },
        '& .MuiCheckbox-root': {
        color: `${colors.greenAccent[200]} !important`,
      },
        '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
        color: `${colors.grey[100]} !important`,
      },
      }}
    >
      <DataGrid
          rows={transactionHistory}
          columns={columnsTrans}
          getRowId={(row) => row.ref}
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: function CustomLoadingOverlay() {
              return <CircularProgress />;
            },
          }} />
    </Box>
        </>
      )}
    </Box>
  );
};

export default AdminDashboard;
