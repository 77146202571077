import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { tokens } from '../../../theme';
import BinaryTree from './BinaryTree';
import { useTranslation } from 'react-i18next';

// const TreeNodeLabel = ({ nodeData }) => (
//     <div style={{ textAlign: 'center' }}>
//         <Avatar>
//             <PersonIcon />
//         </Avatar>
//         <div>{nodeData.name}</div>
//     </div>
// );


const Network = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const { t, i18n } = useTranslation();
  // const [treeData, setTreeData] = useState(null);
  //
  // useEffect(() => {
  //     fetch('./file.json')
  //         .then((response) => response.json())
  //         .then((data) => setTreeData(data));
  // }, []);
  //
  // if (!treeData) {
  //     return <div>Loading...</div>;
  // }


  return (
    <Box m="10px">
      {/* HEADER */}

      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            {t('referral_network_title')}
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            {t('referral_network_subtitle')}
          </Typography>
        </Box>
      </Box>
      <BinaryTree token={props.token} />
    </Box>
  );
};

export default Network;