// docs https://github.com/azouaoui-med/react-pro-sidebar
import React, { useState } from 'react';
import { Menu, MenuItem, Sidebar, useProSidebar } from 'react-pro-sidebar';
import { useSidebarContext } from './sidebarContext';
import { useSignOut } from 'react-auth-kit';
import { Link, useNavigate } from 'react-router-dom';
import { tokens } from '../../theme';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import AppsIcon from '@mui/icons-material/Apps';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import styled from 'styled-components';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';

import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

const GradientSidebar = styled(Sidebar)`
    //background: linear-gradient(to right, #428ee4, #2a5c99);
    background: linear-gradient(120deg, #ffffff, #005aff);
    //background: linear-gradient(120deg, #ffffff, #093a8c);
`;

const Item = ({ title, to, icon, selected, setSelected, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: '#252525' }}
      onClick={() => {
        if (title !== 'Logout') {
          setSelected(title);
        }
        if (onClick) onClick(); // Call the onClick function if it is provided
      }}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
    // <MenuItem
    //     active={selected === title}
    //     style={{ color: '#252525' }}
    //     onClick={() => {
    //         if (title !== 'Logout') {
    //             setSelected(title);
    //         }
    //         if (onClick) onClick(); // Call the onClick function if it is provided
    //     }}
    //     icon={React.cloneElement(icon, { style: { filter: 'drop-shadow(0 0 0.2px white)' } })}
    //     component={<Link to={to} />}
    // >
    //     <Typography style={{ WebkitTextStroke: '0.2px white', fontWeight: 'bold' }}>{title}</Typography>
    // </MenuItem>
  );
};

function MyProSidebar(userData) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState('Dashboard');
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const singOut = useSignOut();
  const navigate = useNavigate();
  const [showAdminOptions, setShowAdminOptions] = useState(false);
  const { t, i18n } = useTranslation();

  const logout = () => {
    singOut();
    navigate('/');
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        display: 'flex',
        height: '100vh',
        top: 0,
        bottom: 0,
        zIndex: 10000,
        '& .sidebar': {
          border: 'none',
        },
        '& .menu-icon': {
          backgroundColor: 'transparent !important',
        },
        '& .menu-item': {
          // padding: "5px 35px 5px 20px !important",
          backgroundColor: 'transparent !important',
        },
        '& .menu-anchor': {
          color: 'inherit !important',
          backgroundColor: 'transparent !important',
        },
        '& .menu-item:hover': {
          color: `${colors.blueAccent[500]} !important`,
          backgroundColor: 'transparent !important',
        },
        '& .menu-item.active': {
          color: `${colors.greenAccent[500]} !important`,
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <GradientSidebar
        breakPoint="md"
        // rtl={sidebarRTL}
        backgroundColor={''}
        // image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              <MenuOutlinedIcon onClick={() => collapseSidebar()} />
            }
            style={{
              margin: '10px 0 20px 0',
              color: '#093a8c',
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  className="logo"
                  alt="Tech Blue-DeFi"
                  width="120px"
                  height="28px"
                  src={'../../../../typo.png'}
                />
                {/*<Typography variant="h3" color={colors.grey[100]}>*/}
                {/*  Tech Blue-DeFi*/}
                {/*</Typography>*/}
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  {/*<CloseOutlinedIcon />*/}
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {!collapsed && (
            <Box mb="25px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  '& .avatar-image': {
                    backgroundColor: colors.primary[500],
                  },
                }}
              >
                <img
                  className="avater-image"
                  alt="profile user"
                  width="120px"
                  height="100px"
                  src={'../../../../logo.png'}
                  style={{ cursor: 'pointer', borderRadius: '50%' }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h3"
                  color={'#093a8c'}
                  fontWeight="bold"
                  sx={{ m: '10px 0 0 0' }}
                >
                  {userData.userData.firstname + ' ' + userData.userData.lastname}
                </Typography>
              </Box>
            </Box>
          )}
          <Box paddingLeft={collapsed ? undefined : '10%'}>
            <Item
              title={t("sidebar_dashboard")}
              to="/home"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {userData.userData.isAdmin === 1 && (
              <>
                <Typography
                variant="h6"
                color={colors.grey[300]}
                sx={{ m: '15px 20px 5px 20px' }}
              >
                  {t("sidebar_administration")}
              </Typography>
                <Item
                  title="Admin Page"
                  to="/home/admin"
                  icon={<PersonOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected} />
              </>
            )}

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: '15px 20px 5px 20px' }}
            >
              {t('sidebar_title_pages')}
            </Typography>
            <Item
              title={t('sidebar_my_referrers')}
              to="/home/referrers"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_packs')}
              to="/home/packs"
              icon={<AddBoxOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_promotional_packs')}
              to="/home/packs/promotional"
              icon={<QueryBuilderOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_store')}
              to="/home/store"
              icon={<LocalGroceryStoreOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_active_packs')}
              to="/home/activepacks"
              icon={<AppsIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_transaction_history')}
              to="/home/history"
              icon={<InsertChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />


            <Item
              title={t('sidebar_referral_network')}
              to="/home/network"
              icon={<GroupsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_deposit')}
              to="/home/deposit"
              icon={<AccountBalanceWalletOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_withdraw')}
              to="/home/withdraw"
              icon={<MonetizationOnOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_rank_prizes')}
              to="/home/rankprizes"
              icon={<EmojiEventsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_promotions')}
              to="/home/promotions"
              icon={<VerifiedOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_suggestions')}
              to="/home/suggestions"
              icon={<MailOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            {/*<Item*/}
            {/*  title="History Balances"*/}
            {/*  to="/home/history"*/}
            {/*  icon={<ReceiptOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}

            <Item
              title={t('sidebar_faq')}
              to="/home/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: '15px 20px 5px 20px' }}
            >
              {t('sidebar_title_profile')}
            </Typography>

            <Item
              title={t('sidebar_profile_settings')}
              to="/home/profile"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title={t('sidebar_logout')}
              to="/"
              icon={<ExitToAppOutlinedIcon />}
              onClick={logout}
              />

            {/*<Item*/}
            {/*  title="Security"*/}
            {/*  to="/home/security"*/}
            {/*  icon={<ShieldOutlinedIcon />}*/}
            {/*  selected={selected}*/}
            {/*  setSelected={setSelected}*/}
            {/*/>*/}

          </Box>
        </Menu>
      </GradientSidebar>
    </Box>
  );
};

export default MyProSidebar;
