import React from 'react';
import './App.css';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import { Login } from './components/login/login';
import { Register } from './components/login/register/register';
import { Home } from './components/home';
import { RequireAuth } from 'react-auth-kit';
import { ForgotPassword } from './components/login/forgot/forgot';
import useToken from './auth/useToken';
import VerificationPage from './auth/verificationPage';
import ResetPassword from './components/login/forgot/ResetPassword';


const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

function App() {
  const { token, removeToken, setToken } = useToken();
  // @ts-ignore
  return (
    <AppContainer>
      <Routes>
        <Route
          path="/home/*"
          element={<Home token={token} setToken={setToken}
                                                    removeToken={removeToken} />}></Route>
        <Route path="/" element={<Login setToken={setToken} />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/verify" element={<VerificationPage />} />
        <Route path={'/reset'} element={<ResetPassword />} />
        <Route path={'/forgot'} element={<ForgotPassword />} />
        {/*<Route path={'/techblue-admins-panel'} element={}*/}
      </Routes>
    </AppContainer>
  );
}

export default App;
