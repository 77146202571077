import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

const Promotions = (props) => {
  const [editProfile, setEditProfile] = useState(false);
  const [editSignIn, setEditSignIn] = useState(false);
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const { t, i18n } = useTranslation();


  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box m="10px">

          <Box
            display={smScreen ? 'flex' : 'block'}
            flexDirection={smScreen ? 'row' : 'column'}
            justifyContent={smScreen ? 'space-between' : 'start'}
            alignItems={smScreen ? 'center' : 'start'}
            m="10px 0"
            p="20px"
            backgroundColor="#285c9a"
          >
            <Box>
              <Typography
                variant="h2"
                color="#FFFFFF"
                fontWeight="bold"
                sx={{ mb: '5px' }}
              >
                {t("promotions_title")}
              </Typography>
              <Typography variant="h5" color="#FFFFFF">
                {t("promotions_subtitle")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
        </>
  );
};

export default Promotions;