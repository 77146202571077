import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { tokens } from '../../../theme';
import axios from 'axios';
import config from '../../../../config';
import { useTranslation } from 'react-i18next';

const Referrers = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userRefferals, setUserRefferals] = React.useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState('');
  const { t, i18n } = useTranslation();
  const columns = [
    { field: 'code', headerName: t("referrer_code"), width: 200 },
    { field: 'username', headerName: t("username"), width: 300 },
    { field: 'join_date', headerName: t("join_date"), width: 300 },
  ];
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const getData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/user/referrals`,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserRefferals(response.data);
        setIsLoading(false);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            {t("my_referrals_list")}
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            {t("welcome_to_your_referrals_list")}
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#285c9a',
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: '#285c9a',
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={userRefferals}
          columns={columns}
          getRowId={(row) => row.code}
          loading={isLoading}
          components={{
            Toolbar: GridToolbar,
            LoadingOverlay: function CustomLoadingOverlay() {
              return <CircularProgress />;
            },
          }}
        />
      </Box>
        )}
    </Box>
  );
};

export default Referrers;
