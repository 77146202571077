import React, { createContext, useContext, useState } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import MyProSidebar from './MyProSidebar';

const SidebarContext = createContext({});

export const MyProSidebarProvider = ({ children, userData }) => {
  const [sidebarRTL, setSidebarRTL] = useState(false);
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,

          sidebarImage,
          setSidebarImage,

          sidebarRTL,
          setSidebarRTL,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: sidebarRTL ? 'row-reverse' : 'row',
          }}
        >
          <MyProSidebar userData={userData} />
          {children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
