import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { tokens } from './theme';
import axios from 'axios';
import config from '../config';

const BarChart = (props, { isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [barChartData, SetBarChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    await axios.get(`${config.baseURL}/api/v1/info/charts/bar-chart`,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      }).then(response => {
      // Open the Snackbar
      SetBarChartData(response.data);
      setIsLoading(false);

    }).catch(error => {
      console.log(error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <ResponsiveBar
          data={barChartData}
          theme={{
            axis: {
              domain: {
                line: {
                  stroke: colors.grey[100],
                },
              },
              legend: {
                text: {
                  fill: colors.grey[100],
                },
              },
              ticks: {
                line: {
                  stroke: colors.grey[100],
                  strokeWidth: 1,
                },
                text: {
                  fill: colors.grey[100],
                },
              },
            },
            legends: {
              text: {
                fill: colors.grey[100],
              },
            },
            tooltip: {
              container: {
                background: colors.primary[400],
                color: colors.grey[100],
              },
            },
          }}
          keys={[
            'Cicle Bonus',
            'Referral Bonus',
          ]}
          indexBy="month"
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.4}
          innerPadding={1}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          valueFormat=" <-"
          colors={{ scheme: 'category10' }}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: 'fries',
              },
              id: 'dots',
            },
            {
              match: {
                id: 'sandwich',
              },
              id: 'lines',
            },
          ]}
          borderRadius={1}
          borderColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                '1.1',
              ],
            ],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : 'month',
            legendPosition: 'middle',
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: isDashboard ? undefined : 'revenue',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [
              [
                'darker',
                1.6,
              ],
            ],
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          barAriaLabel={function(e) {
            return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
          }}
        />
      )}
    </>
  );

};

export default BarChart;