import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from './theme';
import React from 'react';

const StatBox = ({ title, subtitle, progress, increase, icon }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="0 30px" p="12px 0" minHeight={'103.472px'}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography
            variant="h1"
            fontWeight="bold"
            sx={{ color: '#FFFFFF' }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt="2px">
        <Typography variant="h5" sx={{ color: '#FFFFFF' }}>
          {subtitle}
        </Typography>
        <Typography
          variant="h4"
          fontStyle="italic"
          sx={{ color: '#FFFFFF' }}
        >
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
