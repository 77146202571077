import { Box, Button, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import StatBox from '../../../StatBox';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { tokens } from '../../../theme';
import axios from 'axios';
import config from '../../../../config';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import ProgressCircle from "../../../ProgressCircle";


const PromotionalPacks = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [activePacks, setActivePacks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [packAmount, setPackAmount] = useState('');
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [backendMessage, setBackendMessage] = useState('');
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [openMessageDialog, setOpenMessageDialog] = useState(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    ref_id: '',
    balance: { for_savings: 0.00, for_products: 0.00, for_withdraw: 0.00, total: 0.00, on_deposit: 0.00 },
  });
  const [token, setToken] = useState('');
  const [fundType, setFundType] = useState('deposit');
  const { t, i18n } = useTranslation();

  const getData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/user/dashboard`,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);
  const handlePacksPurchase = (product) => {
    // Open the dialog
    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getProducts = async () => {
    try {
      await axios.get(
        `${config.baseURL}/api/v1/info/products/promotional`,
        {
          headers: {
            Authorization: 'Bearer ' + props.token,
          },
        },
      ).then(response => {
        setProducts(response.data);
        setIsLoading(false);
      });

    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogSubmit = async () => {
    setIsLoadingDialog(true);
    // Send the data to the backend
    await axios.post(
      `${config.baseURL}/api/v1/purchase/promotional`,
      {
        amount: packAmount,
        wallet: fundType,
      },
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      // Close the dialog
      handleCloseDialog();
      // Open the Snackbar
      setBackendMessage(response.data.message);
      setIsLoadingDialog(false);
      setOpenMessageDialog(true);


    }).catch(error => {
      // Close the dialog
      handleCloseDialog();
      // Open the Snackbar
      setBackendMessage(error.response.data.message);
      setOpenMessageDialog(true);
    });
  };

  const handleCloseMessageDialog = () => {
    setOpenMessageDialog(false);
  };

  // "#f2f0f0"
  const getGradientColor = (productName) => {
    switch (productName) {
      case 'Promotional Enterprising':
        return 'linear-gradient(180deg, #f2f0f0 30%, #06FA4F 90%)';
      case 'Promotional Executive':
        return 'linear-gradient(180deg, #f2f0f0 30%, #DA06FA 90%)';
      case 'Promotional CEO':
        return 'linear-gradient(180deg, #f2f0f0 30%, #FAF206 90%)';
      default:
        return '#f2f0f0'; // default color if no match
    }
  };


  useEffect(() => {
    getProducts().then(r => console.log(r));
  }, []);

  return (
    <Box m="10px">
      {/* HEADER */}

      <Box
        display={smScreen ? 'flex' : 'block'}
        flexDirection={smScreen ? 'row' : 'column'}
        justifyContent={smScreen ? 'space-between' : 'start'}
        alignItems={smScreen ? 'center' : 'start'}
        m="10px 0"
        p="20px"
        backgroundColor="#285c9a"
      >
        <Box>
          <Typography
            variant="h2"
            color="#FFFFFF"
            fontWeight="bold"
            sx={{ mb: '5px' }}
          >
            {t("promotional_packs_title")}
          </Typography>
          <Typography variant="h5" color="#FFFFFF">
            {t("promotional_packs_subtitle")}
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
            <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
              <Box
                width="100%"
                backgroundColor="#285c9a"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <StatBox
                  title={parseFloat(userData.balance.on_deposit).toLocaleString()}
                  subtitle={t("deposit_funds")}
                  progress="0.5"
                  icon={<AccountBalanceWalletIcon
                    sx={{ color: '#FFFFFF', fontSize: '26px' }} />} />
              </Box>
            </Grid>
          <Grid container spacing={2} m={'20px'}>
            {products.map((product, index) => (
              <React.Fragment key={index}>
                {/*<Grid container item xs={12} spacing={2}>*/}
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ background: getGradientColor(product.name)}} p="20px" borderRadius="8px" minHeight="300px" display="flex"
                       flexDirection="column" justifyContent="space-between">
                    <Box bgcolor="#285c9a" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h5" fontWeight="600" sx={{ textAlign: 'center' }} color={'#FFFFFF'}>
                        {t(product.name)}
                      </Typography>
                    </Box>
                    {product.description.map((desc, i) => (
                      <Typography key={i} variant="body1" color="text.secondary" textAlign={'center'}>
                        {t(desc)}
                      </Typography>
                    ))}
                    <Box bgcolor="#e0e0e0" p="10px" borderRadius="8px" mb="10px">
                      <Typography variant="h6" color="primary" sx={{ textAlign: 'center' }}>
                        ${parseInt(product.price).toLocaleString()}
                      </Typography>
                    </Box>
                      <Button variant="contained" color="primary" sx={{ mt: '10px', mx: 'auto' }}
                              onClick={() => handlePacksPurchase(product)}>
                        {t("dashboard_purchase_button")}
                      </Button>
                  </Box>
                </Grid>
                {/*</Grid>*/}
                {/*<Divider variant="middle" sx={{ my: 2 }} color={"#262626"} />*/}
              </React.Fragment>
            ))}
          </Grid></>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        {isLoadingDialog ? (
          <Box display="flex" justifyContent="center" alignItems="center" m={'10px'}>
            <CircularProgress />
          </Box>
        ) : (
          <><DialogContent>
            <Typography variant={'body1'}>
              {t('packs_enter_amount')}
            </Typography>
            <Select
                value={packAmount}
                onChange={(e) => setPackAmount(e.target.value)}
                fullWidth
            >
              <MenuItem value={5000}>${parseInt(5000).toLocaleString()}</MenuItem>
              <MenuItem value={10000}>${parseInt(10000).toLocaleString()}</MenuItem>
            </Select>
            {/*<TextField*/}
            {/*  autoFocus*/}
            {/*  margin="dense"*/}
            {/*  label={t('investment_amount')}*/}
            {/*  type="number"*/}
            {/*  fullWidth*/}
            {/*  value={packAmount}*/}
            {/*  onChange={(e) => setPackAmount(e.target.value)} />*/}
            <Typography variant={'body1'}>
              {t('select_investment_wallet')}
            </Typography>
            <Select
              value={fundType}
              onChange={(e) => setFundType(e.target.value)}
              fullWidth
            >
              <MenuItem value={'deposit'}>{t('deposit_funds')}</MenuItem>
              {/*<MenuItem value={'reinvestment'}>{t('reinvestment_funds')}</MenuItem>*/}
            </Select>
          </DialogContent><DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('cancel_button')}
            </Button>
            <Button onClick={handleDialogSubmit} color="primary">
              {t('submit_button')}
            </Button>
          </DialogActions></>
        )}
      </Dialog>
      <Dialog open={openMessageDialog} onClose={handleCloseMessageDialog}>
        <DialogContent>
          <Typography variant={'h3'}>
            {t(backendMessage)}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageDialog} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>


  );
};

export default PromotionalPacks;