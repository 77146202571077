import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config';

const VerificationPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    // Make GET request to backend for verification
    axios.get(`${config.baseURL}/api/v1/verify-email/${token}`)
      .then(response => {
        navigate('/');
      })
      .catch(error => {
        navigate('/');
      });
  }, [navigate]);

  return (
    <h1>Loading...</h1>
  );

};

export default VerificationPage;
