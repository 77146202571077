import React, { useState } from 'react';
import { Tree } from 'react-d3-tree';
import axios from 'axios';
import config from '../../../../config';
import { Box, CircularProgress, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


class BinaryTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    // Fetch JSON data from an API endpoint
    axios.get(
      `${config.baseURL}/api/v1/info/user/network`,
      {
        headers: {
          Authorization: 'Bearer ' + this.props.token,
        },
      },
    ).then(response => {
      this.setState({ data: response.data });
    }).catch(error => {
      console.error('Error:', error);
    });
  }


  render() {
    const { data } = this.state;
    if (!data) return <Box display="flex" justifyContent="center" alignItems="center" height="80vh"><CircularProgress /></Box>;

    function NodeLabel({ nodeData = {} }) {
      const [expanded, setExpanded] = useState(false);

      console.log('test test test');
      console.log({ nodeData: nodeData });

      const handleExpandClick = () => {
        setExpanded(!expanded);
      };

      return (
        <foreignObject x={-60} y={-40} width={120} height={80}>
          <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px solid black',
            padding: '5px',
          }}>
            <div>
              <p>Username: {nodeData.name}</p>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
              {expanded && (
                <>
                  <p>Email: {nodeData.attributes.email}</p>
                  <p>Phone: {nodeData.attributes.phone}</p>
                  <p>Referrer Code: {nodeData.attributes.code}</p>
                  <p>{nodeData.attributes.verified === 0 ? 'Not Verified' : 'Verified'}</p>
                  <p>Rank: {nodeData.attributes.rank === null ? 'User does not have a rank' : nodeData.attributes.rank}</p>
                </>
              )}
            </div>
          </div>
        </foreignObject>
      );
    }

    // Convert JSON data to required format for react-d3-tree
    const convertData = (node) => {
      return {
        name: node.code,
        attributes: {
          username: node.username,
          join: node.join_date,
        },
        children: node.children.map(child => convertData(child)),
      };
    };

    const treeData = convertData(data);

    // Tree configuration
    const svgSquare = {
      shape: 'rect',
      shapeProps: { width: 80, height: 80 },
    };

    return (
      <div style={{ width: '100%', height: '80vh' }}>
        <Tree
          data={treeData}
          orientation="vertical"
          translate={{ x: 300, y: 50 }}
          separation={{ siblings: 2, nonSiblings: 2 }}
          nodeSvgShape={svgSquare}
          // nodeSvgShape={<PersonIcon/>}
          pathFunc="step"
          allowForeignObjects
          nodeSize={{ x: 120, y: 80 }} // Adjust the node size to accommodate the box
          nodeLabelComponent={{
            render: (rd3Props) => <NodeLabel {...rd3Props} />,
            foreignObjectWrapper: {
              x: -60,
              y: -40,
              width: 120,
              height: 80,
            },
          }}
        />
      </div>
    );
  }
}

export default BinaryTree;