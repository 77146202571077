import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { HeadingLarge, HeadingMedium, HeadingSmall } from 'baseui/typography';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Body,
  ButtonIcon,
  Container,
  ErrorText,
  InputWrapper,
  LoginForm,
  LoginSubmitButton,
  MainLogo,
  Screen,
  ScreenBackground,
  ScreenBackgroundShape1,
  ScreenBackgroundShape2,
  ScreenBackgroundShape3,
  ScreenBackgroundShape4,
  ScreenContent,
} from '../LoginStyles';
import { Box, TextField } from '@mui/material';
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import ClipLoader from "react-spinners/ClipLoader";

function ForgotPassword(props: any) {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {t, i18n} = useTranslation();

  const navigateToLogin = () => {
    navigate('/');
  };
  const validatePayment = async () => {
    setIsLoading(true);
    await axios.post(
      `${config.baseURL}/api/v1/reset-password`,
      {
        email: email,
      },
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        setIsSubmitted(true);
        setIsLoading(false);
      }

    }).catch(error => {
      setError(error.response.data.message);
    });
  };

  return (
    <Body>
      <Container>
        <Screen>
          <ScreenContent>
            <MainLogo src="./FullLogo.png" />
            {!isSubmitted ? (
              <>
                {/*<LoginForm>*/}
                <Box width={'320px'} p={'156px 30px 30px'}>
                  <Box minHeight={'30px'}>
                    <ErrorText>{t(error)}</ErrorText>
                  </Box>
                  <Box minHeight={'80px'}>
                    <HeadingSmall color={'#292929'}>{t("Enter your Email to restore Password")}</HeadingSmall>
                  </Box>

                  <InputWrapper>
                    <TextField
                      label={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      variant="outlined"
                      style={{ marginBottom: '20px', backgroundColor: '#FFFFFF' }}
                      type={'email'}
                      required={true}
                    />
                  </InputWrapper>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={validatePayment}>
                      {isLoading ? <ClipLoader color="#262626" size={15} /> : t("Send Email")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                      {t("Go back to Login")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                </Box>
                {/*</LoginForm>*/}
              </>
            ) : (
              <>
                <LoginForm>
                  <HeadingSmall alignContent={'center'} color={'#262626'}>{t("Verification email sent to")} {email}</HeadingSmall>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                      {t("Go back to Login")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                </LoginForm>
              </>
            )}
          </ScreenContent>
          <ScreenBackground>
            <ScreenBackgroundShape1 />
            <ScreenBackgroundShape2 />
            <ScreenBackgroundShape3 />
            <ScreenBackgroundShape4 />
          </ScreenBackground>
        </Screen>
      </Container>
    </Body>
  );
}

export { ForgotPassword };
