import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import {
  Body,
  ButtonIcon,
  Container,
  ErrorText,
  InputWrapper,
  LoginForm,
  LoginSubmitButton,
  MainLogo,
  Screen,
  ScreenBackground,
  ScreenBackgroundShape1,
  ScreenBackgroundShape2,
  ScreenBackgroundShape3,
  ScreenBackgroundShape4,
  ScreenContent,
} from '../LoginStyles';
import {Box, IconButton, InputAdornment, TextField} from '@mui/material';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { HeadingLarge, HeadingSmall } from 'baseui/typography';
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {useFormik} from "formik";
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [verification, setVerification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const {t , i18n} = useTranslation();
  const bcrypt = require('bcryptjs');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = yup.object().shape({
    password: yup.string()
        .min(8, t('Password must be at least 8 characters long'))
        .matches(/[^A-Za-z0-9]/, t('Password must contain at least one special character'))
        .required(t('Password is required')),
  });


  useEffect(() => {
    // Extract token from URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    // Make GET request to backend for verification
    axios.get(`${config.baseURL}/api/v1/reset-password/${token}`)
      .then(response => {
        setVerification(true);
      })
      .catch(error => {
        navigate('/');
      });
  }, [navigate]);

  const onSubmit = async (values: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const hashedPassword = bcrypt.hashSync(values.password, config.salt);

    setIsLoading(true);
    await axios.post(`${config.baseURL}/api/v1/reset-password/${token}`,
        {...values,
        password: hashedPassword}).then(response => {
      navigate('/');
    }).catch((error) => {
      setIsLoading(false);
      setError(error.response?.data.message);
    });
  };

  const navigateToLogin = () => {
    navigate('/');
  };

  const formik = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Body>
      <Container>
        <Screen>
          <ScreenContent>
            <MainLogo src="./FullLogo.png" />
            {verification ? (
              <>
                {/*<LoginForm>*/}
                <Box width={'320px'} p={'156px 30px 30px'}>
                  <form onSubmit={formik.handleSubmit}>
                  <Box minHeight={'30px'}>
                    <ErrorText>{error}</ErrorText>
                  </Box>
                  <Box minHeight={'100px'}>
                    <HeadingLarge color={'#292929'}>{t("Enter your new password")}</HeadingLarge>
                  </Box>

                  <InputWrapper>
                    <TextField
                        name={'password'}
                        label={t('New Password')}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        variant="outlined"
                        required={true}
                        style={{ width: '100%', color: '#262626', backgroundColor: '#FFFFFF' }}
                        type={showPassword ? 'text' : 'password'}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                          endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                          ),
                        }} />
                  </InputWrapper>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={onSubmit}>
                      {t("submit_button")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                      {t("Go back to Login")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                  </form>
                </Box>
                {/*</LoginForm>*/}
              </>
            ) : (
              <>
                <LoginForm>
                  <HeadingSmall alignContent={'center'} color={'#262626'}>{t("Invalid verification token")}</HeadingSmall>
                  <InputWrapper>
                    <LoginSubmitButton type="submit" onClick={navigateToLogin}>
                      {t("Go back to Login")}
                      <ButtonIcon>
                        <ArrowForwardIosOutlinedIcon />
                      </ButtonIcon>
                    </LoginSubmitButton>
                  </InputWrapper>
                </LoginForm>
              </>
            )}
          </ScreenContent>
          <ScreenBackground>
            <ScreenBackgroundShape1 />
            <ScreenBackgroundShape2 />
            <ScreenBackgroundShape3 />
            <ScreenBackgroundShape4 />
          </ScreenBackground>
        </Screen>
      </Container>
    </Body>
  );

};

export default ResetPassword;
