import { Route, Routes } from 'react-router-dom';
import { MyProSidebarProvider } from '../global/sidebar/sidebarContext';
import Topbar from '../global/Topbar';
import Dashboard from './pages/dashboard';
import History from './pages/history';
import Referrers from './pages/referrers';
import Profile from './pages/profile';
import Bar from './charts/bar';
import Line from './charts/line';
import Pie from './charts/pie';
import FAQ from './pages/faq';
import Packs from './pages/packs';
import { ColorModeContext, themeSettings, useMode } from '../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { createTheme } from '@mui/material/styles';
import ActivePacks from './pages/activepacks';
import Tree from './pages/network';
import Deposit from './pages/deposit';
import Withdraw from './pages/withdraw';
import axios from 'axios';
import config from '../../config';
import Store from './pages/store';
import AdminRoute from '../AdminRoute';
import AdminDashboard from './administration/AdminDashboard';
import Promotions from './pages/promotions';
import RankPrizes from './pages/rankprizes';
import Suggestions from './pages/suggestions';
import PromotionalPacks from './pages/promotionalpacks';

interface HomeProps {
  token: string | null;
  setToken: (userToken: string) => void;
  removeToken: () => void;
}

const Home: React.FC<HomeProps> = ({ token, setToken, removeToken }) => {
  const colorModeContextValue = useMode();
  const theme = useMemo(() => createTheme(themeSettings(colorModeContextValue.colorMode)), [colorModeContextValue.colorMode]);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '', user_volume_rank: '', isAdmin: false
  });

  const getData = async () => {
    await axios.get(
      `${config.baseURL}/api/v1/info/user/dashboard`,
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    ).then(response => {
      if (response.status === 200) {
        response.data.access_token && setToken(response.data.access_token);
        setUserData(response.data);
      }

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  useEffect(() => {
    getData().then(r => console.log(r));
  }, []);

  return (
    <ColorModeContext.Provider value={colorModeContextValue}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MyProSidebarProvider userData={userData}>
          <div style={{ height: '100%', width: '100%' }}>
            <main>
              <Topbar token={token} userData={userData} />
              <Routes>
                <Route path="/" element={<Dashboard token={token} />} />
                <Route path="/referrers" element={<Referrers token={token} />} />
                <Route path="/history" element={<History token={token} />} />
                <Route path="/profile" element={<Profile token={token} />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/packs" element={<Packs token={token} />} />
                <Route path="/activepacks" element={<ActivePacks token={token} />} />
                <Route path="/network" element={<Tree token={token} />} />
                <Route path={'/deposit'} element={<Deposit token={token} />} />
                <Route path={'/withdraw'} element={<Withdraw token={token} />} />
                <Route path={'/store'} element={<Store token={token} />} />
                <Route path="/admin" element={<AdminDashboard isAdmin={userData.isAdmin} token={token} />} />
                <Route path="/promotions" element={<Promotions token={token} />} />
                <Route path="/rankprizes" element={<RankPrizes token={token} />} />
                <Route path="/suggestions" element={<Suggestions token={token} />} />
                <Route path="/packs/promotional" element={<PromotionalPacks token={token}/>} />
                <Route path={"/*"} element={<Dashboard token={token} />} />
              </Routes>
            </main>
          </div>
        </MyProSidebarProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>

  );
};


export { Home };
