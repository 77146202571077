// src/components/home/pages/dashboard/WelcomeDialog.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const WelcomeDialog = ({ open, onClose, content }) => {
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("info_withdraw_title")}</DialogTitle>
      <DialogContent>
        <Typography dangerouslySetInnerHTML={{ __html: content }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">{t("close_button")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default WelcomeDialog;