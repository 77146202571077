import React, { useContext, useState } from 'react';
import { ColorModeContext, tokens } from '../theme';
import { Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useProSidebar } from 'react-pro-sidebar';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import config from '../../config';
import { useFormik } from 'formik';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import SpainFlag from '../assets/SpainFlag.svg';
import UKFlag from '../assets/UKFlag.svg';
import USFlag from '../assets/USFlag.svg';
import {useSignOut} from "react-auth-kit";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";


const Topbar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { toggleSidebar, broken, rtl } = useProSidebar();
  const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const singOut = useSignOut();
  const [open, setOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const logout = () => {
    singOut();
    navigate('/');
  };

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const handleFormSubmit = async (values) => {
    await axios.post(
      `${config.baseURL}/api/v1/form/support`,
      values,
      {
        headers: {
          Authorization: 'Bearer ' + props.token,
        },
      },
    ).then(response => {
      setSubmitted(true);

    }).catch(error => {
      console.error('Error:', error);
    });
  };

  const handleIconClick = () => {
    setOpen(true);
  };

  const supportForm = useFormik({
    initialValues: {
      message: '',
    },
    onSubmit: handleFormSubmit,
  });

  return (
    <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" p={2} backgroundColor="#dddddd">
      <Box display="flex" flexDirection="row" alignItems={{ xs: 'center', sm: 'flex-start' }}>
        {broken && !rtl && (
          <IconButton
            sx={{ margin: '0 6 0 2' }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <img
          className="logo"
          alt="Tech Blue-DeFi"
          width="340px"
          height="38px"
          src={'../../../../typo.png'}
          style={{ cursor: 'pointer' }}
        />
        {/*<Typography variant="h2" color="#285c9a" fontWeight="bold">*/}
        {/*  Tech Blue-DeFi*/}
        {/*</Typography>*/}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box display={"flex"}>
        <Box justifyContent={'center'} justifyItems={'center'} p={'10px'}>
          <Typography variant="h4" color="#285c9a" fontWeight="bold">
            {props.userData.user_volume_rank === null ? t('volume_rank_not_available') : t('volume_rank') + t(props.userData.user_volume_rank)}
          </Typography>
        </Box>
        <Box justifyContent={'center'} justifyItems={'center'} p={'10px'}>
          <Box onClick={toggleLanguage} style={{ cursor: 'pointer' }}>
            {currentLanguage === 'en' ? <img src={SpainFlag} alt="Spain Flag" width="30px" height="30px"/> : <img src={USFlag} alt="US Flag" width="30px" height="30px"/>}
          </Box>
        </Box>
          <IconButton onClick={logout} >
            <ExitToAppOutlinedIcon />
          </IconButton>
        <IconButton onClick={handleIconClick}>
          <HeadsetMicOutlinedIcon />
        </IconButton>
        <IconButton onClick={() => navigate('/home/profile')}>
          <PersonOutlinedIcon />
        </IconButton>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <Typography variant="h4" color="textPrimary" sx={{ m: 2 }}>
            {t("support_form_label4")}
          </Typography>
          <form onSubmit={supportForm.handleSubmit}>
            <DialogContent>
              <TextField
                name={'message'}
                label={t("problem_or_question")}
                value={supportForm.values.message}
                onChange={supportForm.handleChange}
                variant="outlined"
                required={true}
                style={{ marginBottom: '20px', width: '100%', color: '#262626' }}
              />
              {submitted &&
                <Typography variant="body1" color="success">{t("support_form_label1")}</Typography>}
              <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
              {t("support_form_label2")}
              </Typography>
              <Typography variant="body1" color="textSecondary" sx={{ m: 2 }}>
                {t("support_form_label3")}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>{t("cancel_button")}</Button>
              <Button type="submit">{t("submit_button")}</Button>
            </DialogActions>
          </form>
        </Dialog>

        {broken && rtl && (
          <IconButton
            sx={{ margin: '0 6 0 2' }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
